/**
 * We use translations schemas from sanity in main app code for two purposes:
 * 1. define typings for translations
 * 2. get default values for translations
 */
import globalHeader from '../../studio/schemas/translations/globalHeader';
import globalSearch from '../../studio/schemas/translations/globalSearch';
import statusPrOverview from '../../studio/schemas/translations/statusPrOverview';
import statusPrDetails from '../../studio/schemas/translations/statusPrDetails';
import statusPoOverview from '../../studio/schemas/translations/statusPoOverview';
import approvalsPrOverview from '../../studio/schemas/translations/approvalsPrOverview';
import globalPagination from '../../studio/schemas/translations/globalPagination';
import approvalsInvoices from '../../studio/schemas/translations/approvalsInvoices';
import approvalsInvoiceDetails from '../../studio/schemas/translations/approvalsInvoiceDetails';
import statusPoDetails from '../../studio/schemas/translations/statusPoDetails';
import loginPage from '../../studio/schemas/translations/loginPage';
import globalBreadcrumb from '../../studio/schemas/translations/globalBreadcrumb';
import globalFilters from '../../studio/schemas/translations/globalFilters';
import globalError from '../../studio/schemas/translations/globalError';
import statusContractDetails from '../../studio/schemas/translations/statusContractDetails';
import statusInvoice from '../../studio/schemas/translations/statusInvoice';
import statusInvoiceDetail from '../../studio/schemas/translations/statusInvoiceDetail';
import statusContactOverview from '../../studio/schemas/translations/statusContractOverview';
import approvalsPrDetails from '../../studio/schemas/translations/approvalsPrDetails';
import manualPrDraft from '../../studio/schemas/translations/manualPrDraft';

export const defaultTranslations = {
  globalHeader: globalHeader.fields,
  globalSearch: globalSearch.fields,
  globalFilters: globalFilters.fields,
  statusPrOverview: statusPrOverview.fields,
  statusPrDetails: statusPrDetails.fields,
  statusPoDetails: statusPoDetails.fields,
  statusPoOverview: statusPoOverview.fields,
  statusContractDetails: statusContractDetails.fields,
  approvalsPrOverview: approvalsPrOverview.fields,
  globalPagination: globalPagination.fields,
  approvalsInvoices: approvalsInvoices.fields,
  approvalsInvoiceDetails: approvalsInvoiceDetails.fields,
  approvalsPrDetails: approvalsPrDetails.fields,
  loginPage: loginPage.fields,
  globalBreadcrumb: globalBreadcrumb.fields,
  globalError: globalError.fields,
  statusInvoice: statusInvoice.fields,
  statusInvoiceDetail: statusInvoiceDetail.fields,
  statusContactOverview: statusContactOverview.fields,
  manualPrDraft: manualPrDraft.fields,
} as const;

export type Translations = typeof defaultTranslations;

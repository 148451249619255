import { TranslationsSchema } from './types';

export default {
  name: 'approvalsInvoices',
  title: 'Approvals Invoices',
  sanityId: 'approvalsInvoices',
  fields: {
    page_title: 'Invoice Approvals Overview',
    no_data: 'no data',
    invoice_type: 'Invoice Type',
    overdue: 'OVERDUE',
    supplier_id: 'Supplier ID',
    amount: 'Gross Amount',
    invoice_date: 'Invoice Date',
    company_code: 'Company Code',
    view_in_IVT: 'View in IVT',
  },
} satisfies TranslationsSchema;

import { TranslationsSchema } from './types';

export default {
  name: 'approvalsInvoiceDetails',
  title: 'Approvals Invoices Details',
  sanityId: 'approvalsInvoiceDetails',
  fields: {
    page_title: 'Invoice Approvals Detail',
    no_data: 'no data',
    split_accounting: 'Split Accounting',

    invoice: 'Invoice',
    ready_for_approval: 'Ready For Approval',

    gross_amount: 'Gross Amount:',
    net_amount: 'Net Amount:',
    tax_amount: 'Tax Amount:',
    approval_amount: 'Approval Amount:',

    task_ID: 'Task ID',
    task_type: 'Task Type',
    invoice_number: 'Invoice Number',
    invoice_type: 'Invoice Type',
    company_code: 'Company Code',
    invoice_date: 'Invoice Date',
    posting_type: 'Posting Type',
    supplier: 'Supplier',
    supplier_ID: 'Supplier ID',
    view_in_IVT: 'View in IVT',

    line_item_pattern: 'Line Item {LineItemNum}',
    PO_number: 'PO Number:',

    invoice_information: 'Invoice Information',
    cost_center: 'Cost Center',
    GL_account: 'GL Account',
    internal_order: 'Internal Order',
    asset_number: 'Asset Number',
    task_assigned_date: 'Task Assigned Date',

    amounts: 'Amounts',
    PO_amount: 'PO Amount',
    line_net_amount: 'Line Net Amount',
    debit_credit_indicator: 'Debit/Credit Indicator',
    debit_credit_indicator_true: 'True',
    debit_credit_indicator_false: 'False',

    notes: 'Notes',
    approval_reason: 'Approval Reason',

    view_pdf_label:
      'Review the invoice PDF, and return to this screen to approve or reject the invoice.',
    view_pdf_caption: 'View Invoice PDF',
    approve_caption: 'APPROVE',
    reject_caption: 'REJECT',

    cancel_button_caption: 'CANCEL',
    close_button_caption: 'CLOSE',
    goto_overview_button_caption: 'GO TO OVERVIEW PAGE',

    approve_header_pattern: 'Approve invoice {InvoiceNumber}',
    approve_text: "Make sure you've carefully reviewed the invoice for accuracy and completeness.",
    approve_comment_label: 'Comment:',
    approve_comment_placeholder: 'Add comment ...',
    approve_confirm_header: 'Confirm approval',
    approve_confirm_text:
      'Are you sure you want to approve this invoice? This action cannot be undone.',
    approve_success_header: 'Invoice approved',
    approve_success_text_pattern: "You've successfully approved invoice {InvoiceNumber}.",

    approve_error_header: "Sorry, we've encountered an error.",
    approve_error_already_approved:
      'It looks like someone has already taken action on this invoice.',
    approve_error_locked: "Action couldn't complete, as Invoice appeared to be locked by someone.",
    approve_error_not_ready:
      "Action couldn't complete, as Invoice appeared to be not ready for approval.",
    approve_error_goto_overview: 'Please go to Overview page and start again from there.',

    reject_header_pattern: 'Reject invoice {InvoiceNumber}',
    reject_text: "Make sure you've carefully reviewed the invoice for accuracy and completeness.",

    reject_reason_label: 'Rejection Reason:',
    reject_comment_label: 'Comment:',
    reject_comment_placeholder: 'Add comment ...',
    reject_confirm_header: 'Confirm rejection',
    reject_confirm_text:
      'Are you sure you want to reject this invoice? This action cannot be undone.',

    reject_success_header: 'Invoice rejected',
    reject_success_text_pattern: "You've rejected invoice {InvoiceNumber}.",
  },
} satisfies TranslationsSchema;
